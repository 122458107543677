import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Layout } from 'src/components/Layout';
import { Link } from 'src/components/links/Link';
import { SeasonsQuery } from 'src/graphqlTypes';

interface SeasonsProps {
    data: SeasonsQuery;
}

const Seasons: FC<SeasonsProps> = ({ data }) => {
    const seasons = data.allContentfulSeason.nodes;

    return (
        <Layout>
            {seasons.map((season) => (
                <Link key={season.id} to={`/season-${season.seasonNumber}`}>
                    Season {season.seasonNumber}
                </Link>
            ))}
        </Layout>
    );
};

export const query = graphql`
    query Seasons {
        allContentfulSeason {
            nodes {
                id
                seasonNumber
            }
        }
    }
`;

export default Seasons;
